import moment from 'moment-timezone'

export const initializeFbPixel = pixelId => {
  if (window.fbq) {
    fbq('init', pixelId, {})
  }
}
export const fBPixelTrackSingle = (pixelId, event, data) => {
  if (pixelId && window.fbq) {
    fbq('trackSingle', pixelId, event, data)
  }
}

export const fBPixelTrack = (eventName, data) => {
  if (window.fbq) {
    window.fbq('track', eventName, data)
  }
}

const createAdobeAnalyticsEvent = eventName => {
  /** This allows us to dispatch browser events in old IE and newer browsers */
  let event
  if (typeof CustomEvent === 'function') {
    event = new CustomEvent(eventName)
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, true, true, {})
  }
  document.dispatchEvent(event)
}

const pushEvent = args => {
  try {
    dataLayer.push(args)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error pushing event: ${args.event} to the data layer. Google tag may be badly configured.`)
  }
}

export const selectDate = (dateSelected, venueName) => {
  pushEvent({ event: 'selectDate', dateSelected, venue: venueName })
}

export const completeUpgradesPage = upsells => {
  const selectedUpgrades = Object.keys(upsells.inventoryEdits).map(key => ({ id: key, quantity: upsells.inventoryEdits[key].quantity }))
  pushEvent({ event: 'completeUpgradesPage', selectedUpgrades })
}

export const changePreferredLanguage = languageCode => {
  pushEvent({ event: 'changePreferredLanguage', languageCode })
}

export const backButtonPressed = () => {
  pushEvent({ event: 'backButtonPressed' })
}

export const widgetLoaded = venue => {
  pushEvent({ event: 'widgetLoaded', venue })
}

export const advanceForward = () => {
  pushEvent({ event: 'advanceForward' })
}

export const embeddedWidgetLoaded = () => {
  pushEvent({ event: 'embeddedWidgetLoaded' })
}

export const login = loginType => {
  pushEvent({ event: 'login', loginType })
  createAdobeAnalyticsEvent('customerLogin')
}

export class OfferAnalytics {
  selectOffer(offerName, venueName) {
    pushEvent({ event: 'selectOffer', offerName, venueName })
  }

  bookOfferClick() {
    pushEvent({ event: 'bookOfferClick' })
  }
}

export const requestSubmitted = (data, venueName, venueInternalName) => {
  pushEvent({ ...data, venue: venueName, event: 'requestSubmitted', venueInternalName })
  fBPixelTrack('Schedule')
}

export const successfulCheckout = (data, venueName, venueInternalName, venueId, currencyParam) => {
  pushEvent(
    Object.assign(data, {
      venue: venueName,
      event: 'successfulCheckout',
      venueInternalName,
    })
  )
  fBPixelTrack('Purchase', {
    partySize: data.partySize,
    amount: data.charge,
    currency: currencyParam,
    merchant_id: venueId,
    reservation_date: moment(data.date).format('YYYY-MM-DD'),
    reservation_time: moment(data.time, ['h:mm A']).format('h:mm:ss Z'),
  })
  fBPixelTrack('Schedule')
}

export const currencyConversion = (value, currency) => {
  gtag('event', 'conversion', {
    send_to: 'AW-809495831/92AuCNfJ44ABEJfa_4ED',
    value,
    currency,
  })
}

export const failedCheckout = errors => {
  pushEvent({ event: 'failedCheckout', errors })
}

export const appliedPromoCode = promoCodeId => {
  pushEvent({ event: 'appliedPromoCode', promoCodeId })
}

export const selectVenue = (venueSelected, venueInternalName) => {
  pushEvent({ event: 'selectVenue', venueSelected, venueInternalName })
}

export const search = data => {
  pushEvent({ event: 'search', ...data })
  createAdobeAnalyticsEvent('reservationSearch')
}

export const searchButtonClicked = data => {
  pushEvent({ ...data, event: 'searchButtonClicked' })
}

export const selectTimeSlot = data => {
  pushEvent({ ...data, event: 'selectTimeslot' })
  createAdobeAnalyticsEvent('reservationTime')
  fBPixelTrack('InitiateCheckout')
}

export const logReservationSummaryEvent = () => {
  pushEvent({ event: 'reservationReview' })
  createAdobeAnalyticsEvent('reservationReview')
}

export const reservationConfirmation = () => {
  createAdobeAnalyticsEvent('reservationConfirmation')
}
