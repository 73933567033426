import flex from '@cybersource/flex-sdk-web'
import fetch from 'isomorphic-fetch'
import 'whatwg-fetch'
import _ from 'lodash'
import { stripeCard } from 'svr/common/stripeCard'
import { setDataFor3Dsecure } from 'svr/component-lib/Widget/Payments/actions'
import { SET_STRIPE_INTENT_CLIENT_SECRET } from 'svr/component-lib/Widget/Payments/ActionTypes'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'
import * as PaymentResultStatus from 'widget/universal/components/payments/response'
import { isUnifiedPaymentResponse } from 'widget/universal/components/payments/util'
import { AccountTypes, TransactionTypes } from '../../../../lib/Payments/Constants'
import { CardCodes } from '../../../../lib/Payments/CyberSource'
import { RSAEncrypt } from '../../../../lib/Payments/FreedomPay'
import { FREEDOMPAY_SOFT_DECLINE_CODES } from '../../../../lib/Payments/FreedomPayGateway'
import { AESEncrypt, PipeFormat } from '../../../../lib/Payments/Network'
import { IntentSources } from '../../../../lib/Payments/StripeGateway'
import { convertToFormData, prepChargeData, prepResData, prepUserData } from '../utils/convertData'
import {
  POST_CHECKOUT_FAILURE,
  POST_CHECKOUT_SUCCESS,
  SET_FREEDOMPAY_INITIAL_DATA,
  SET_FREEDOMPAY_VISIBLE,
  SET_FREEDOMPAY_IFRAME_INITIALIZED,
  STRIPE_ERROR,
  TRY_POST_CHECKOUT,
} from './ActionTypes'
import { payerAuthenticationCheckEnrollmentComplete, payerAuthenticationSetupComplete } from './cybersourceThreeDs'
import { dismissModal } from './navigation'

export const tryPostCheckout = textSpinnerMessage => ({
  type: TRY_POST_CHECKOUT,
  spinner_message: textSpinnerMessage,
})
export const postCheckoutSuccess = () => ({ type: POST_CHECKOUT_SUCCESS })
export const postCheckoutFailure = errorMessage => ({
  type: POST_CHECKOUT_FAILURE,
  errorMessage,
})

export const postCheckoutPageReloadOnFailure = (errorMessage, okMessage, actionCallback) => ({
  type: ActionTypes.POST_CHECKOUT_RELOAD_ON_FAILURE,
  errorMessage,
  okMessage,
  actionCallback,
})

export const setFreedompayInitialData = data => ({
  type: SET_FREEDOMPAY_INITIAL_DATA,
  data,
})

export const setFreedompayIframeInitialized = data => ({
  type: SET_FREEDOMPAY_IFRAME_INITIALIZED,
  data,
})

export const setFreedompayVisible = data => ({
  type: SET_FREEDOMPAY_VISIBLE,
  data,
})

export const setIntentClientSecret = stripeIntentClientSecret => ({
  type: SET_STRIPE_INTENT_CLIENT_SECRET,
  stripeIntentClientSecret,
})

export const initializationFailure = (element, textCommonPaymentErrorConnectionError) => dispatch => {
  dispatch(postCheckoutFailure(textCommonPaymentErrorConnectionError))
}

export const fetchOrRefreshFreedompayDataForIframe = (venueId, paymentMethod, styles) => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const url = `${baseUrl}/api-yoa/payments/${venueId}/begin_payment`

  const chargeData = prepChargeData(state)
  const formData = new FormData()
  const formFields = state.formFields.toJS()

  formData.append('total', chargeData.charge_amount * 100)
  formData.append('tax', chargeData.charge_tax_amount * 100)
  formData.append('styles', styles)
  formData.append('payment_method', paymentMethod)

  // will be ignored for non-hpc on BE
  formData.append('address1', chargeData?.address1)
  formData.append('city', chargeData?.city)
  formData.append('locality', chargeData?.locality)
  formData.append('administrativeArea', chargeData?.administrativeArea)
  formData.append('countryCode', chargeData?.countryCode)
  formData.append('postalCode', chargeData?.postalCode)

  formData.append('firstName', `${formFields.cardFirstName}`)
  formData.append('lastName', `${formFields.cardLastName}`)

  formData.append('email', `${formFields?.email}`)
  formData.append('phone', `+${formFields?.dialCode}${formFields?.phoneNumber}`)
  // will be ignored for non-hpc on BE

  dispatch(setFreedompayIframeInitialized(false))
  fetch(url, {
    body: formData,
    method: 'POST',
  })
    .then(response => response.json())
    .then(response => {
      dispatch(setFreedompayInitialData(response.data.begin_payment))
      dispatch(setFreedompayIframeInitialized(true))
    })
}

export const submitFreedompayPaymentRequest =
  (venueId, freedompayData, paymentMethod, processingSpinner, commonPaymentErrors) => (dispatch, getState) => {
    const state = getState()
    const { baseUrl } = state.widgetSettings
    const authUrl = `${baseUrl}/api-yoa/payments/${venueId}/authorize`

    const chargeData = prepChargeData(state)

    const authData = new FormData()
    const attrs = freedompayData.attributes
    const cardData = getFreedompayCardData(attrs, freedompayData.paymentType)
    const formFields = state.formFields.toJS()

    authData.append('paymentKey', freedompayData.paymentKeys[0])
    authData.append('sessionKey', state.payment.freedompay.initialData.sessionKey)
    authData.append('total', chargeData.charge_amount * 100)
    authData.append('tax', chargeData.charge_tax_amount * 100)
    authData.append('name', `${formFields.cardFirstName} ${formFields.cardLastName}`)
    authData.append('transactionType', TransactionTypes.WEB_PAYLINK_CHARGE)
    authData.append('paymentMethod', paymentMethod)

    // will be ignored for non-hpc on BE
    authData.append('address1', chargeData?.address1)
    authData.append('locality', chargeData?.locality)
    authData.append('administrativeArea', chargeData?.administrativeArea)
    authData.append('countryCode', chargeData?.countryCode)
    authData.append('postalCode', chargeData?.postalCode)

    authData.append('firstName', `${formFields.cardFirstName}`)
    authData.append('lastName', `${formFields.cardLastName}`)

    authData.append('email', `${formFields?.email}`)
    authData.append('phone', `+${formFields?.dialCode}${formFields?.phoneNumber}`)
    // will be ignored for non-hpc on BE

    dispatch(tryPostCheckout(processingSpinner))
    fetch(authUrl, {
      body: authData,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(response => {
        if (response.status !== 200) {
          if (FREEDOMPAY_SOFT_DECLINE_CODES.includes(response.msg)) {
            window.FreedomPay.Apm.ConsumerAuth.invoke({ mandateChallenge: true })
            return
          }
          dispatch(postCheckoutFailure(response.msg))
          dispatch(fetchOrRefreshFreedompayDataForIframe(venueId, paymentMethod))
          return
        }
        dispatch(
          submitPaymentCheckoutDataWithToken(
            response.data.authorization.token,
            {
              ...cardData,
              transactionId: response.data.authorization.transaction_id,
              invoiceId: response.data.authorization.invoice_id,
              posSyncId: response.data.authorization.pos_sync_id,
              purchaserCode: response.data.authorization.purchaser_code,
              nameOnCard: response.data.authorization.name_on_card,
              sessionKey: response.data.authorization.session_key,
              transactionType: TransactionTypes.WEB_PAYLINK_CHARGE,
              cofComplianceData: response.data.authorization.cof_compliance_data,
              paymentMethod,
            },
            processingSpinner,
            commonPaymentErrors
          )
        )
      })
      .catch(err => {
        dispatch(postCheckoutFailure(err))
        dispatch(fetchOrRefreshFreedompayDataForIframe(venueId, paymentMethod))
      })
  }

const getFreedompayCardData = (attrs, paymentType) => {
  let maskedCard
  let brand
  let expirationDate
  let postalCode
  if (paymentType === 'GooglePay') {
    const { info } = attrs.filter(attr => attr.Key === 'Raw')[0]?.Value?.paymentMethodData
    maskedCard = info?.cardDetails
    brand = info?.cardNetwork
  } else if (paymentType === 'ApplePay') {
    const networkNumber = attrs.filter(attr => attr.Key === 'maskedCardNumber')[0]?.Value.split(' ')
    // eslint-disable-next-line prefer-destructuring
    maskedCard = networkNumber[1]
    // eslint-disable-next-line prefer-destructuring
    brand = networkNumber[0]
  } else {
    maskedCard = attrs.find(attr => attr.Key === 'MaskedCardNumber')?.Value
    brand = attrs.find(attr => attr.Key === 'CardIssuer')?.Value
    postalCode = attrs.find(attr => attr.Key === 'PostalCode')?.Value
    expirationDate = attrs.find(attr => attr.Key === 'ExpirationDate')?.Value
    return { maskedCard, brand, postalCode, expirationDate }
  }
  return { maskedCard, brand }
}

export const stripeError = (stripeField, errorMessage) => ({
  type: STRIPE_ERROR,
  stripeField,
  errorMessage,
})

const setPaymentPendingResponse = paymentPendingResponse => ({
  type: ActionTypes.SET_PAYMENT_PENDING_RESPONSE,
  paymentPendingResponse,
})

// eslint-disable-next-line consistent-return
const postCheckout = (url, formData, jsonData, state, dispatch, textCommonPaymentErrorUnableToProcess, isStripePaymentElement = false) => {
  if (jsonData.card_token && state.venueInfo.threedSecure && state.venueInfo.paymentType === AccountTypes.NETWORK) {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = `/booking/${state.venueInfo.id}/3d_secure_routing/`

    // eslint-disable-next-line guard-for-in
    for (const key in jsonData) {
      const input = document.createElement('input')
      input.name = key
      input.value = jsonData[key]
      form.appendChild(input)
    }
    const router = document.createElement('input')
    router.name = 'router'
    router.value = 'paylink'
    form.appendChild(router)

    document.body.appendChild(form)
    return form.submit()
  }

  fetch(url, {
    body: formData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (isUnifiedPaymentResponse(state.venueInfo.paymentType)) {
        if ((response.status || response) === PaymentResultStatus.RESULT_PENDING) {
          if (response?.redirect_url) {
            window.location = response.redirect_url
          } else {
            dispatch(setPaymentPendingResponse(response))
          }
        } else if ((response.status || response) === PaymentResultStatus.RESULT_SUCCESS) {
          dispatch(postCheckoutSuccess())
        } else {
          dispatch(postCheckoutFailure(response.message))
        }
      } else if (response.adyen_action_for_3d_secure && state.venueInfo.paymentType === AccountTypes.ADYEN) {
        dispatch(setDataFor3Dsecure(response.adyen_action_for_3d_secure))
      } else if (state.venueInfo.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX && response.enrollment_result) {
        dispatch(payerAuthenticationCheckEnrollmentComplete(response))
      } else if ((response.message || response) === 'success') {
        dispatch(postCheckoutSuccess())
      } else {
        // The following block of code reloads the Shift4 iframe, which is an unfortunately necessary hacky way to ensure that
        // the credit card fields can accept input after payment via Shift4 fails.
        if (state.venueInfo.paymentType === AccountTypes.SHIFT_4) {
          const shift4iframe = document.getElementById('i4goFrame')?.querySelector('iframe')
          if (shift4iframe) {
            shift4iframe.src += ''
          }
        }
        dispatch(postCheckoutFailure(response.payload.errors[0]))
        if (isStripePaymentElement) {
          dispatch(createStripeIntent())
        }
      }
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log(e)
      dispatch(postCheckoutFailure(textCommonPaymentErrorUnableToProcess))
      if (isStripePaymentElement) {
        dispatch(createStripeIntent())
      }
    })
}

export const postCheckoutFetch =
  (state, token, textCommonPaymentErrorUnableToProcess, preppedCharge = null, sessionId = null, isStripePaymentElement = false) =>
  dispatch => {
    const postUrl = `${state.widgetSettings.baseUrl}/submit/paylink/${state.venueInfo.venueUrlKey}`
    const { selectedTimeSlot } = state.actualInfo
    const partySize = state.actualInfo.guests
    const prepped = preppedCharge || prepChargeData(state, token)
    if (isStripePaymentElement) {
      dispatch(createStripeIntent())
    }
    prepped.sessionId = sessionId
    const postData = _.assign({}, prepResData(selectedTimeSlot, partySize, state), prepUserData(state), prepped)
    const formattedPostData = convertToFormData(postData)

    postCheckout(postUrl, formattedPostData, postData, state, dispatch, textCommonPaymentErrorUnableToProcess, isStripePaymentElement)
  }

const stripePaymentElementErrorEventHandler = (state, error) => dispatch => {
  const { paymentTypeMessage } = state.commonPayment
  const paymentTypeErrorMessage = `Something went wrong with ${paymentTypeMessage}. Please choose a different payment method and try again.`
  if (error?.type === 'validation_error') {
    dispatch(dismissModal())
  } else if (error?.type === 'invalid_request_error' && error?.message.includes(paymentTypeMessage)) {
    dispatch(stripeError('', paymentTypeErrorMessage))
  }
}

const updateAndSubmitPaymentElement =
  (stripe, elements, result, chargeData, stripeIntentClientSecret, commonPaymentErrors) => (dispatch, getState) => {
    const state = getState()

    const intentForm = new FormData()
    intentForm.append('amount', chargeData.charge_amount)
    intentForm.append('token', result?.paymentIntent?.id)

    fetch(`/booking/widget/${state.venueInfo.urlKey}/update_payment_intent`, {
      body: intentForm,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      // eslint-disable-next-line consistent-return
      .then(response => {
        if (response.errors) {
          let messageStringFinal = ''
          response.errors.forEach(errorItem => {
            const messageString = errorItem.split(':')
            messageStringFinal += `* ${messageString[1]}`
          })
          dispatch(stripeError(messageStringFinal))
        } else {
          elements.fetchUpdates().then(() => {
            dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret, commonPaymentErrors))
          })
        }
      })
  }

const submitPaymentElement = (stripe, elements, stripeIntentClientSecret, commonPaymentErrors) => (dispatch, getState) => {
  const state = getState()
  const { textCommonPaymentErrorUnableToProcess, stripeZhHkStrings } = commonPaymentErrors

  const formData = new FormData()
  const chargeData = prepChargeData(state)
  formData.append('amount', chargeData.charge_amount)
  formData.append('source', IntentSources[TransactionTypes.WEB_PAYLINK_CHARGE])
  elements.submit().then(ev => {
    if (ev?.error) {
      dispatch(stripePaymentElementErrorEventHandler(state, ev.error))
      return
    }
    stripe
      .confirmPayment({
        elements,
        clientSecret: stripeIntentClientSecret,
        redirect: 'if_required',
      })
      .then(response => {
        if (response.error) {
          dispatch(stripeError(response.error.type, stripeZhHkStrings[response.error.code] || response.error.message))
        } else {
          const cardToken = response.paymentIntent.id
          dispatch(postCheckoutFetch(state, cardToken, textCommonPaymentErrorUnableToProcess, null, null, true))
        }
      })
  })
}

export const stripePaymentMethodSubmitHandler = (stripe, commonPaymentErrors) => (dispatch, getState) => {
  const state = getState()
  const { textCommonPaymentErrorUnableToProcess, stripeZhHkStrings } = commonPaymentErrors

  const { elements, stripeIntentClientSecret } = state.commonPayment

  if (state.billingInfo.card_request) {
    elements.submit().then(ev => {
      if (ev?.error) {
        dispatch(stripePaymentElementErrorEventHandler(state, ev.error))
        return
      }
      stripe
        .confirmSetup({
          elements,
          clientSecret: stripeIntentClientSecret,
          redirect: 'if_required',
        })
        .then(response => {
          if (response.error) {
            dispatch(stripeError(response.error.param, stripeZhHkStrings[response.error.code] || response.error.message))
          } else {
            const cardToken = response.setupIntent.id
            dispatch(postCheckoutFetch(state, cardToken, textCommonPaymentErrorUnableToProcess, null, null, true))
          }
        })
    })
  } else {
    const chargeData = prepChargeData(state)
    stripe.retrievePaymentIntent(stripeIntentClientSecret).then(result => {
      if (result?.paymentIntent?.amount !== chargeData.charge_amount * 100) {
        dispatch(updateAndSubmitPaymentElement(stripe, elements, result, chargeData, stripeIntentClientSecret, commonPaymentErrors))
      } else {
        dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret, commonPaymentErrors))
      }
    })
  }
}

export const createStripeIntent = () => (dispatch, getState) => {
  const state = getState()

  const formData = new FormData()
  const calced = prepChargeData(state)
  formData.append('amount', calced.charge_amount)
  if (state.billingInfo.card_request) {
    const url = `/booking/widget/${state.venueInfo.urlKey}/setup_intent`
    return new Promise(resolve => {
      let secret = ''
      fetch(url, {
        body: formData,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => response.json())
        // eslint-disable-next-line consistent-return
        .then(response => {
          if (response.errors) {
            let messageStringFinal = ''
            response.errors.forEach(errorItem => {
              const messageString = errorItem.split(':')
              messageStringFinal += `* ${messageString[1]}`
            })
            dispatch(stripeError('', messageStringFinal))
          } else {
            secret = response.client_secret
            dispatch(setIntentClientSecret(secret))
            resolve({ intentClientSecret: secret })
          }
          return secret
        })
    })
  }
  const url = `/booking/widget/${state.venueInfo.urlKey}/payment_intent`
  const chargeData = prepChargeData(state)
  formData.append('amount', chargeData.charge_amount)
  formData.append('source', IntentSources[TransactionTypes.WEB_PAYLINK_CHARGE])
  return new Promise(resolve => {
    let secret = ''
    fetch(url, {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      // eslint-disable-next-line consistent-return
      .then(response => {
        if (response.errors) {
          let messageStringFinal = ''
          response.errors.forEach(errorItem => {
            const messageString = errorItem.split(':')
            messageStringFinal += `* ${messageString[1]}`
          })
          dispatch(stripeError('', messageStringFinal))
        } else {
          secret = response.payload.client_secret
          dispatch(setIntentClientSecret(secret))
          resolve({ intentClientSecret: secret })
        }
        return secret
      })
  })
}

export const submitPaymentCheckoutData = (stripe, processingSpinner, commonPaymentErrors) => (dispatch, getState) => {
  const state = getState()
  const formFields = state.formFields.toJS()
  const {
    textCommonPaymentErrorUnableToProcess,
    textCommonPaymentErrorUnexpectedError,
    textCommonPaymentErrorPaymentRejectedError,
    textCommonPaymentErrorProcessingError,
  } = commonPaymentErrors

  dispatch(tryPostCheckout(processingSpinner))

  if (state.venueInfo.paymentType === AccountTypes.STRIPE) {
    return dispatch(stripePaymentMethodSubmitHandler(stripe, commonPaymentErrors))
  } else if (state.venueInfo.paymentType === AccountTypes.FREEDOMPAY) {
    const rsaEncryptor = new RSAEncrypt(FREEDOM_PAY_PUBLIC_KEY)
    const encryptedCard = rsaEncryptor.freedomPayCard(
      formFields.cardNum,
      formFields.cardMonthExp,
      formFields.cardYearExp,
      formFields.cardCvv
    )
    dispatch(postCheckoutFetch(state, encryptedCard, textCommonPaymentErrorUnableToProcess))
  } else if (state.venueInfo.paymentType === AccountTypes.NETWORK) {
    const aesEncryptor = new AESEncrypt(state.billingInfo.encryption_key)
    const piper = new PipeFormat()
    const calced = prepChargeData(state)
    const { cardNum, cardMonthExp, cardYearExp, cardCvv, cardFirstName, cardLastName } = formFields
    const cardHolderName = `${cardFirstName} ${cardLastName}`
    const request = piper.buildRequest(
      cardNum,
      cardMonthExp,
      cardYearExp,
      cardCvv,
      cardHolderName,
      calced.charge_amount,
      'SALE',
      state.venueInfo.urlKey,
      `?billing_history_id=${state.billingInfo.billing_history_id}`
    )
    const encryptedRequest = aesEncryptor.encrypt(request)
    dispatch(postCheckoutFetch(state, encryptedRequest, textCommonPaymentErrorUnableToProcess))
  } else if (state.venueInfo.paymentType === AccountTypes.CYBERSOURCE_REDUX) {
    fetch(`/booking/widget/${state.venueInfo.id}/jwk`, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(resp => {
        if (!resp?.kid) {
          dispatch(postCheckoutFailure(String('Failed to retrieve jwk key')))
        } else {
          const options = {
            kid: resp.kid,
            keystore: resp,
            encryptionType: 'rsaoaep256',
            cardInfo: {
              cardNumber: formFields.cardNum,
              cardType: CardCodes[stripeCard.cardType(formFields.cardNum).toUpperCase()],
              cardExpirationMonth: `0${formFields.cardMonthExp}`.substr(-2),
              cardExpirationYear: String(formFields.cardYearExp),
            },
            production: state.app.env === 'PRODUCTION',
          }
          flex.createToken(options, response => {
            if (response.error) {
              // eslint-disable-next-line no-alert
              alert(textCommonPaymentErrorProcessingError)
              return
            }
            // eslint-disable-next-line no-param-reassign
            response.expiryMonth = formFields.cardMonthExp
            // eslint-disable-next-line no-param-reassign
            response.expiryYear = formFields.cardYearExp
            const preppedData = prepChargeData(state, response.token, response)
            dispatch(postCheckoutFetch(state, response.token, textCommonPaymentErrorUnableToProcess, preppedData))
          })
        }
      })
  } else if (state.venueInfo.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX) {
    dispatch(submitPaymentCheckoutDataCybersourceThreeDSRedux())
  } else if (state.venueInfo.paymentType === AccountTypes.NETWORK_REDUX) {
    let paymentReference = null
    window.NI.generateSessionId()
      .then(response => {
        const formData = new FormData()
        const chargeData = prepChargeData(state)
        const formFields = state.formFields.toJS()
        formData.append('session_id', response.session_id)
        formData.append('amount', chargeData.charge_amount)
        formData.append('first_name', formFields.firstName)
        formData.append('last_name', formFields.lastName)
        formData.append('email_address', formFields.email)
        formData.append('actual_id', state.actualInfo.id)

        fetch(`/booking/widget/${state.venueInfo.urlKey}/auth_charge`, {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
        })
          .then(response => {
            if (!response.ok && response.status === 500) {
              // eslint-disable-next-line no-throw-literal
              throw `We encountered an error: ${response.status} ${response.statusText}`
            }
            return response
          })
          .then(response => response.json())
          .then(response => {
            dispatch(dismissModal())
            if (response.errors) {
              // eslint-disable-next-line no-throw-literal
              throw `We encountered an error: ${response.errors.join(', ')}`
            }
            paymentReference = [response._id.split(':')[2], response.orderReference].join('|')

            return window.NI.handlePaymentResponse(response, {
              mountId: 'cc3dsMountId',
              style: {
                width: 500,
                height: 500,
              },
            }).then(response => {
              const { status } = response
              if (status === window.NI.paymentStates.AUTHORISED || status === window.NI.paymentStates.CAPTURED) {
                dispatch(postCheckoutFetch(state, paymentReference, textCommonPaymentErrorUnableToProcess))
              } else if (status === window.NI.paymentStates.FAILED || status === window.NI.paymentStates.THREE_DS_FAILURE) {
                dispatch(postCheckoutFailure(textCommonPaymentErrorPaymentRejectedError))
              }
            })
          })
          .catch(error => {
            dispatch(postCheckoutFailure(String(error)))
          })
      })
      .catch(error => {
        const message = error.message || textCommonPaymentErrorUnexpectedError

        dispatch(postCheckoutFailure(String(message)))
      })
  } else if (state.venueInfo.paymentType === AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE) {
    window.NI.generateSessionId()
      .then(response => {
        dispatch(postCheckoutFetch(state, null, textCommonPaymentErrorUnableToProcess, null, response.session_id))
      })
      .catch(error => {
        const message = error.message || textCommonPaymentErrorUnexpectedError
        dispatch(postCheckoutFailure(String(message)))
      })
  } else {
    dispatch(postCheckoutFetch(state, null, textCommonPaymentErrorUnableToProcess))
  }
}

export const submitCheckoutData = commonPaymentErrors => (dispatch, getState) => {
  const state = getState()
  const { selectedTimeSlot } = state.actualInfo
  const partySize = state.actualInfo.guests

  const postData = _.assign({}, prepResData(selectedTimeSlot, partySize, state), prepUserData(state))

  const formattedPostData = convertToFormData(postData)
  const postUrl = `${state.widgetSettings.baseUrl}/booking/dining/widget/${state.venueInfo.venueUrlKey}/book`
  postCheckout(postUrl, formattedPostData, postData, state, dispatch, commonPaymentErrors.textCommonPaymentErrorUnableToProcess)
}

export const submitPaymentCheckoutDataWithToken = (token, cardData, processingSpinner, commonPaymentErrors) => (dispatch, getState) => {
  dispatch(tryPostCheckout(processingSpinner))
  let preppedCharge
  if (cardData) {
    preppedCharge = prepChargeData(getState(), token, cardData)
  }
  dispatch(postCheckoutFetch(getState(), token, commonPaymentErrors.textCommonPaymentErrorUnableToProcess, preppedCharge))
}

const startReduxAuthenticationServiceSetup = chargeData => (dispatch, getState) => {
  dispatch(tryPostCheckout('Processing...'))
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const url = `${baseUrl}/api-yoa/cybersource/auth/${state.venueInfo.id}/get_initial_data`
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(chargeData),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 200) {
        dispatch(payerAuthenticationSetupComplete(response.data))
      } else {
        dispatch(postCheckoutFailure(response.msg))
      }
    })
    .catch(() => dispatch(postCheckoutFailure('Unable to process request. Please try again')))
}

export const submitPaymentCheckoutDataCybersourceThreeDSRedux = () => (dispatch, getState) => {
  const state = getState()
  const formFields = state.formFields.toJS()
  const url = `/booking/widget/${state.venueInfo.urlKey}/jwk`
  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(resp => {
      if (!resp?.kid) {
        dispatch(postCheckoutFailure(String('Failed to retrieve jwk key')))
      } else {
        const options = {
          kid: resp.kid,
          keystore: resp,
          encryptionType: 'rsaoaep256',
          cardInfo: {
            cardNumber: formFields.cardNum,
            cardType: CardCodes[stripeCard.cardType(formFields.cardNum).toUpperCase()],
            cardExpirationMonth: `0${formFields.cardMonthExp}`.slice(-2),
            cardExpirationYear: String(formFields.cardYearExp),
          },
          production: state.app.env === 'PRODUCTION',
        }
        flex.createToken(options, response => {
          if (response.error) {
            // eslint-disable-next-line no-alert
            alert('We could not process your card.')
            return
          }
          // eslint-disable-next-line no-param-reassign
          response.expiryMonth = formFields.cardMonthExp
          // eslint-disable-next-line no-param-reassign
          response.expiryYear = formFields.cardYearExp
          const preppedData = prepChargeData(state, response.token, response)
          dispatch(startReduxAuthenticationServiceSetup(preppedData))
        })
      }
    })
}

export const paymentEnrollmentCybersourceThreeDsRedux = (cardToken, cardData) => (dispatch, getState) => {
  const prepData = prepChargeData(getState(), cardToken, cardData)
  dispatch(postCheckoutFetch(getState(), cardToken, 'Unable to process request. Please try again', prepData))
}
