import { Map } from 'immutable'
import { GET_EVENTS_SUCCESS } from '../actions/ActionTypes'

const events = (state = Map(), action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS: {
      const { events } = action.data

      for (const eventId in events) {
        if (!Object.prototype.hasOwnProperty.call(events, eventId)) {
          continue
        }

        const event = events[eventId]
        const firstSvgImage = event.photoMap && Object.values(event.photoMap).find(photo => !!photo.photoDict?.isSvg)
        const hasInteractiveFloorplan = action.venueInfo.enable3DFloorplan && firstSvgImage !== undefined
        const interactiveFloorplanUrl = hasInteractiveFloorplan ? firstSvgImage.url : undefined
        events[eventId].hasInteractiveFloorplan = hasInteractiveFloorplan
        events[eventId].interactiveFloorplanUrl = interactiveFloorplanUrl
      }

      return state.merge(action.data.events)
    }
    default:
      return state
  }
}

export default events
