/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
import _ from 'lodash'
import React from 'react'
import { keyDownHandlerWrapper, AccessibleDivComponent } from 'svr/common/A11y'
import { CroppedImage, AutoFallbackImage } from '@sevenrooms/core/ui-kit/layout'
import * as styles from '../assets/styles/eventList'

const ListItem = ({
  id,
  monthSeperator,
  nextMonthFlag,
  onEventItemClick,
  dayName,
  dateText,
  eventLabel,
  thumbSrc,
  selectedDate,
  thumbFallback,
  cropData,
}) => {
  const seperator = nextMonthFlag ? monthSeperator : <hr style={styles.lineItemSeperator} />
  const onKeyDownHandler = keyDownHandlerWrapper.bind(null, onEventItemClick)
  let eventHighlight
  if (_.startsWith(id, selectedDate)) {
    eventHighlight = <div onClick={onEventItemClick} onKeyDown={onKeyDownHandler} style={styles.eventHighlight} />
  }
  return (
    <div id={id} style={{ position: 'relative', width: '100%' }}>
      {seperator}
      {eventHighlight}
      <AccessibleDivComponent aria-label={`${eventLabel} - ${dateText}`} style={styles.itemWrapper} onClick={onEventItemClick}>
        <div style={styles.vertTextWrapper}>
          <span style={styles.vertText}>{dayName.substr(0, 3)}</span>
        </div>
        <div className="event-image-thumb" style={styles.imageWrapper}>
          <AutoFallbackImage
            imageUrl={thumbSrc}
            style={styles.image}
            fallback={<CroppedImage src={thumbFallback} cropData={cropData} style={styles.image} />}
          />
        </div>
        <div style={styles.textWrapper}>
          <span style={styles.dateItem}>{dateText}</span>
          <span style={styles.textItem}>{eventLabel}</span>
        </div>
      </AccessibleDivComponent>
    </div>
  )
}

export default ListItem
