import { promoCodeTypes } from './constantTypes'

export const roundMoney = amount => {
  const roundedAmount = Math.round(amount * 100) / 100
  return roundedAmount.toFixed(2)
}

export const makeCalc = (x, y) => {
  const total = x * y
  return roundMoney(total)
}

export const calcAdditionalFee = (subtotal, chargeAdditional, additionalFee, quantity) => {
  if (!chargeAdditional) {
    return 0
  }
  if (additionalFee.get('feeAmountType') === 'PERCENT_OF_BASE') {
    return Number(roundMoney((Number(additionalFee.get('feeAmount')) * subtotal) / 100))
  }
  if (additionalFee.get('feeAmountType') === 'PER_TICKET') {
    return Number(roundMoney(Number(additionalFee.get('feeAmount')) * quantity))
  }
  return Number(roundMoney(Number(additionalFee.get('feeAmount'))))
}

export const getDiscountedPrice = (basePrice, promoCodeEntity) => {
  if (!promoCodeEntity) {
    return 0
  }
  const discountedPrice = applyPromo(basePrice, promoCodeEntity)
  return Math.min(basePrice, discountedPrice)
}

export const calcCharges = (
  selectedInventory,
  quantity,
  price,
  serviceChargePercentage,
  taxPercentage,
  gratuityPercentage,
  additionalFee,
  promoCodeEntity,
  additionalFeeTaxPercentage
) => {
  const initialAmount = Number(roundMoney(price * quantity))

  const discount = getDiscountedPrice(initialAmount, promoCodeEntity)

  const chargeBaseAmount = initialAmount - discount

  const chargeServiceChargeAmount = Number(roundMoney(chargeBaseAmount * (serviceChargePercentage / 100)))

  const chargeGratuityAmount = Number(roundMoney(chargeBaseAmount * (gratuityPercentage / 100)))

  const chargeTaxAmount = Number(roundMoney((chargeBaseAmount + chargeServiceChargeAmount) * (taxPercentage / 100)))

  const chargeAdditionalFeeAmount = calcAdditionalFee(
    chargeBaseAmount,
    selectedInventory.get('chargeAdditionalFee'),
    additionalFee,
    quantity
  )
  const chargeAdditionalFeeTaxAmount = Number(roundMoney(chargeAdditionalFeeAmount * (additionalFeeTaxPercentage / 100)))

  const subTotal = calcTotal(chargeBaseAmount, chargeServiceChargeAmount, chargeTaxAmount, chargeGratuityAmount)

  const total = calcTotal(subTotal, chargeAdditionalFeeAmount, chargeAdditionalFeeTaxAmount)
  return {
    charge_amount: total,
    charge_base_amount: initialAmount,
    charge_service_charge_amount: chargeServiceChargeAmount,
    charge_gratuity_amount: chargeGratuityAmount,
    charge_tax_amount: chargeTaxAmount,
    charge_additional_amount: chargeAdditionalFeeAmount,
    charge_additional_fee_tax_amount: chargeAdditionalFeeTaxAmount,
  }
}

export const calcTotalFeePrice = (inventoryItem, quantity, price, defaultServiceCharge) => {
  let serviceChargeAmount = 0
  if (inventoryItem.applyServiceCharge) {
    if (inventoryItem.serviceChargeType === 'SPECIFIC_SERVICE_CHARGE') {
      serviceChargeAmount = inventoryItem.serviceChargeAmount
    } else {
      // Workaround for inventories that were saved with DEFAULT_SERVICE_CHARGE and serviceChargeAmount = 0
      serviceChargeAmount = defaultServiceCharge
    }
  }

  const charges = calcCharges(
    new Map(Object.entries(inventoryItem)),
    quantity,
    price,
    serviceChargeAmount,
    0,
    inventoryItem.chargeGratuity && inventoryItem.chargeGratuity !== 'CUSTOMER_VARIABLE' ? inventoryItem.gratuityAmount : 0,
    new Map(Object.entries(inventoryItem.additionalFee)),
    null,
    0
  )
  return charges.charge_gratuity_amount + charges.charge_additional_amount + charges.charge_service_charge_amount
}

export const applyPromo = (subTotal, promoCodeEntity) => {
  if (!promoCodeEntity) {
    return subTotal
  }
  switch (promoCodeEntity.promoType) {
    case promoCodeTypes.DOLLAR_DISCOUNT:
      return promoCodeEntity.promoValue / 100
    case promoCodeTypes.PERCENT_DISCOUNT:
      return subTotal * (promoCodeEntity.promoValue / 100)
    case promoCodeTypes.PERCENT_DISCOUNT_WITH_CAP: {
      const roundedDiscount = subTotal * (promoCodeEntity.promoValue / 100)
      const valueCap = promoCodeEntity.promoValueCap / 100
      return Math.min(roundedDiscount, valueCap)
    }
    default:
      return promoCodeEntity.promoValue / 100
  }
}

export const calcTotal = (...params) => params.reduce((p, c) => parseFloat(p || 0) + parseFloat(c || 0)).toFixed(2)

export const getTaxRate = (taxGroups, taxGroupId) => {
  const taxGroup = taxGroups.find(obj => obj.id === taxGroupId)
  const tax = taxGroup ? taxGroup.tax_rate : 0
  return tax
}

export const calculatePricingDetails = state => {
  const { taxGroups, venueDefaultServiceCharge: defaultServiceCharge, venueDefaultGratuity: defaultGratuity } = state.venueInfo
  const { promoCode: promoCodeEntity } = state.entities
  const customTip = state.formFields.get('customTip') || 0
  const pricingDetails = []

  state.inventoryCart.cart.forEach(({ inventoryId, quantity }) => {
    if (quantity === 0) {
      return
    }

    const inventory = state.entities.inventory.get(inventoryId)
    const tipPercent = inventory.get('gratuityAmount')
    const chargeGratuity = inventory.get('chargeGratuity')
    const chargeGratuityType = inventory.get('gratuityAmountType')
    const chargeAdditionalFee = inventory.get('chargeAdditionalFee')
    const chargeAdditionalFeeTax = inventory.get('chargeAdditionalFeeTax')
    const additionalFee = inventory.get('additionalFee')
    const taxGroupId = inventory.get('taxGroupId')
    const taxPercent = getTaxRate(taxGroups, taxGroupId)
    const chargeTax = inventory.get('chargeTax') && taxPercent !== 0
    const applyServiceCharge = inventory.get('applyServiceCharge')
    const serviceChargeType = inventory.get('serviceChargeType')
    const serviceChargePercent =
      serviceChargeType === 'DEFAULT_SERVICE_CHARGE' ? defaultServiceCharge : inventory.get('serviceChargeAmount')
    const price = inventory.get('price')
    const initialPrice = makeCalc(quantity, price)
    let discountedPrice = 0
    if (promoCodeEntity) {
      discountedPrice = getDiscountedPrice(initialPrice, promoCodeEntity)
    }
    const basePrice = initialPrice - discountedPrice
    const serviceChargeAmt = applyServiceCharge ? makeCalc(basePrice, serviceChargePercent / 100) : 0
    const taxAmt = chargeTax ? makeCalc(+basePrice + +serviceChargeAmt, taxPercent / 100) : 0
    const tipOption = {
      DEFAULT_FIXED: defaultGratuity,
      CUSTOM_FIXED: tipPercent,
      CUSTOMER_VARIABLE: customTip,
    }[chargeGratuityType]
    const tipAmt = chargeGratuity ? makeCalc(basePrice, tipOption / 100) : makeCalc(basePrice, 0)
    const additionalFeeTaxId = inventory.get('additionalFeeTaxId')
    const additionalFeeTaxPercent = getTaxRate(taxGroups, additionalFeeTaxId)
    const additionalFeeAmount = calcAdditionalFee(basePrice, chargeAdditionalFee, additionalFee, quantity)
    const additionalFeeTaxAmount = chargeAdditionalFeeTax ? makeCalc(additionalFeeAmount, additionalFeeTaxPercent / 100) : 0
    const subTotal = calcTotal(basePrice, serviceChargeAmt, taxAmt, tipAmt)
    const total = calcTotal(subTotal, additionalFeeAmount, additionalFeeTaxAmount)

    pricingDetails.push({
      inventoryId,
      basePrice: initialPrice,
      discountedPrice,
      tax: taxAmt,
      taxPercent,
      chargeTax,
      serviceCharge: serviceChargeAmt,
      serviceChargePercent,
      additionalFeeAmount,
      additionalFeeTaxAmount,
      additionalFeeTaxPercent,
      tip: tipAmt,
      subTotal,
      total,
    })
  })

  return pricingDetails
}
